import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import clsx from "clsx";

const NavMenu = ({ menuWhiteClass, sidebarMenu }) => {
  const { t } = useTranslation();
  const { categories } = useSelector((state) => state.category);
  const { brands } = useSelector((state) => state.brand);
  const { carriers } = useSelector((state) => state.carrier);

  // map categories to navMenu in 4 columns of unlimited rows
  const mapCategories = (categories, mainLink) => {
    const sortedCategories = [...categories].filter(cat=>cat.priorityNumber!=null).sort((a, b) => a.priorityNumber - b.priorityNumber);
    const columns = [];
    sortedCategories.forEach((item, index) => {
      columns.push(
        <li key={item._id} className="col-md-4 col-sm-6 col-xs-6 text-wrap">
          <Link
            to={`/shop?${mainLink}=${item.slug}`}
            className="dropdown-link-hover"
          >
            {item.name}
          </Link>
        </li>
      );
    });
    return columns;
  };

  const navMenu = [
    {
      id: 1,
      category: t("Home"),
      link: "/",
      image: "/assets/img/menu/3.png",
    },
    {
      id: 2,
      category: t("New Arrivals"),
      link: "/shop?filter=new-arrival",
      image: "/assets/img/menu/2.png",
    },
    {
      id: 3,
      category: t("Categories"),
      // link: "/categories",
      image: "/assets/img/menu/1.png",
      mainLink: "category",
      submenu: categories,
    },
    {
      id: 4,
      category: t("Brands"),
      // link: "/brands",
      image: "/assets/img/menu/6.png",
      mainLink: "brand",
      submenu: brands,
    },
    {
      id: 5,
      category: t("Carriers"),
      // link: "/shop",
      image: "/assets/img/menu/5.png",
      mainLink: "carrier",
      submenu: carriers,
    },
    {
      id: 6,
      category: t("Clearnce"),
      // link: "/clearnce",
      link: "/clearance",
      image: "/assets/img/menu/4.png",
    },
    {
      id: 7,
      category: t("Contact Us"),
      link: "/contact",
      image: "/assets/img/menu/7.png",
    },
  ];

  return (
    <div
      className={clsx(
        sidebarMenu
          ? "sidebar-menu"
          : `main-menu ${menuWhiteClass ? menuWhiteClass : ""}`
      )}
    >
      <nav>
        <ul>
          {navMenu.map((item, index) => (
            <>
              <li key={index} className="custom-dropdown-toggle">
                {item.link ? (
                  <Link to={process.env.PUBLIC_URL + item.link}>
                    <div className="d-flex justify-content-center">
                      <img src={item.image} alt="" />
                    </div>
                    {item.category}
                  </Link>
                ) : (
                  <>
                    <div
                      style={{
                        cursor: "pointer",
                        fontSize: "15px",
                        fontWeight: 500,
                        lineHeight: "40px",
                        whiteSpace: "nowrap",
                        display: "inline-block",
                        letterSpacing: "0.8px",
                        color: "#fff",
                      }}
                    >
                      <div className="d-flex justify-content-center">
                        <img src={item.image} alt="" />
                      </div>
                      {item.category}
                    </div>
                  </>
                )}

                {item.submenu && (
                  <ul className="submenu-dropdown">
                    {mapCategories(item.submenu, item.mainLink)}
                  </ul>
                )}
              </li>
            </>
          ))}
        </ul>
      </nav>
    </div>
  );
};

NavMenu.propTypes = {
  menuWhiteClass: PropTypes.string,
  sidebarMenu: PropTypes.bool,
};

export default NavMenu;
